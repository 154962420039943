import Vue from 'vue'
import $store from '@/store'
import $router from '@/router'

// ---||  [ Iniciar session ]  ||--- //
export async function login({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('username', payload.correo)
  form.append('password', payload.password)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  form.append(
    'tokenFavorite',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/loginMailPassword',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorLogin = true
      state.msgError = e.response.data.message
      console.log(e)
    })
    .finally(() => {
      console.log('addProduct Done')
      $store.state.loaderDerek = false
    })
}
// ---||  [ Enviar código de login a correo ]  ||--- //
export async function sendCodLoginToEmail({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('reenvio', payload.isReSend)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/sendPasswordToMail',
    data: form,
  })
    .then(() => {
      console.log('waiting code...')
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgError = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgError = message
      }
      state.errorLogin = true
    })
    .finally(() => {
      console.log('sendCodeToEmail Done')
      $store.state.loaderDerek = false
    })
}
// ---||  [ Validar código de ingreso por correo ]  ||--- //
export async function sendCodLoginFromEmail({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('codigo_acceso', payload.code)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  form.append(
    'tokenWhislist',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/validateCodeLogin',
    data: form,
  })
    .then(response => {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgError = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgError = message
      }
      state.errorLogin = true
    })
    .finally(() => {
      console.log('ValidateCodeEmail Done')
      $store.state.loaderDerek = false
    })
}
// ---||  [ Enviar código de recordar contraseña ]  ||--- //
export async function sendCodLogin({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.email)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/sendCodeResetPassword',
    data: form,
  })
    .then(() => {
      console.log('waiting code...')
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgError = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgError = message
      }
      state.errorLogin = true
    })
    .finally(() => {
      console.log('sendCoderememberPass Done')
      $store.state.loaderDerek = false
    })
}
//---||  [ Cambiar contraseña con código ]  ||--- //
export async function changePasswordCode({ state }, payload) {
  state.errorLogin = false
  state.msgError = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('password_new', payload.password)
  form.append('password_new_confirmed', payload.passwordConfirm)
  form.append('codigo_acceso', payload.cod)
  form.append(
    'tokenCart',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/resetPassword',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
      location.reload()
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgError = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgError = message
      }
      state.errorLogin = true
    })
    .finally(() => {
      console.log('addProduct Done')
      $store.state.loaderDerek = false
    })
}
// ---||  [ Cerrar sesion ]  ||--- //
export async function logout({ state }) {
  $store.state.loaderDerek = true
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/auth/logout',
  })
    .then(() => {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('tokenCart')
      $store.state.cart.productsCart = null
      window.localStorage.removeItem('tokenFavorite')
      state.dataUser = null
      state.directions = null
      $store.state.products.productWhislist = []
      $store.state.products.productWhislist = []
      $router.push({ name: 'Home' }).catch(() => {})
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('Logout Done')
      $store.state.loaderDerek = false
    })
}
// ---||  [ Regustrarse ]  ||--- //
export async function register({ state }, payload) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('nombres', payload.nombres)
  // form.append("apellidos", payload.apellidos);
  // form.append("password", payload.password);
  // form.append("password_confirmed", payload.password_confirmed);
  // form.append("hb_month", payload.hb_month);
  // form.append("hb_day", payload.hb_day);
  // form.append("newsletter", payload.newsletter);
  // form.append("tokenCart", payload.tokenCart);
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/register',
    data: form,
  })
    .then(function() {
      // state.dataUser = response.data.user;
      // window.localStorage.setItem('accessToken', response.data.access_token)
      // window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      // window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorRegister = true
      if (e.response.data.message) {
        state.msgErrorRegister = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorRegister = message
      }
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('Send form register Done')
    })
}
// ---||  [ Regustrarse ]  ||--- //
export async function validateCodRegister({ state }, payload) {
  state.errorRegister = false
  state.msgErrorRegister = ''
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('email', payload.correo)
  form.append('password', payload.password)
  form.append('password_confirmed', payload.passwordConfirm)
  form.append('codigo_acceso', payload.codigo)
  form.append('tokenCart', window.localStorage.getItem('tokenCart'))
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/auth/completeRegister',
    data: form,
  })
    .then(function(response) {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
      window.localStorage.setItem('accessToken', response.data.access_token)
      window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist)
      window.localStorage.setItem('tokenCart', response.data.tokenCart)
    })
    .catch(e => {
      state.errorRegister = true
      if (e.response.data.message) {
        state.msgErrorRegister = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorRegister = message
      }
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('Send código register Done')
    })
}
// ---||  [ Obtener información de usuario ]  ||--- //
export async function getProfile({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/auth/profile',
  })
    .then(response => {
      state.dataUser = response.data.user
      state.directions = response.data.direcciones
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('getProfile Done')
    })
}
// ---||  [ Actualizar información de usuario ]  ||--- //
export async function updatePerfil({ state }) {
  state.msgErrorUpdatePerfil = ''
  state.errorUpdatePerfil = false
  var form = new FormData()
  form.append('nombres', state.dataUser.persona.nombres)
  form.append('apellidos', state.dataUser.persona.apellidos)
  form.append('tipodoc', state.dataUser.persona.tipodoc)
  form.append('documento', state.dataUser.persona.documento)
  form.append('celular', state.dataUser.persona.celular)
  form.append('genero', state.dataUser.persona.genero)
  form.append('datemonth', state.dataUser.persona.fechanac_mes)
  form.append('dateday', state.dataUser.persona.fechanac_dia)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/auth/profile/update',
    data: form,
  })
    .then(response => {
      state.dataUser = response.data.user
    })
    .catch(e => {
      state.errorUpdatePerfil = true
      if (e.response.data.message) {
        state.msgErrorUpdatePerfil = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorUpdatePerfil = message
      }
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}
// ---||  [ Actualizar contraseña de usuario ]  ||--- //
export async function updatePassword({ state }, payload) {
  state.msgErrorUpdatePassPerfil = ''
  state.errorUpdatePassPerfil = false
  var form = new FormData()
  form.append('password_new', payload.newPassword)
  form.append('password_actual', payload.passwordNow)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/auth/profile/update_password',
    data: form,
  })
    .then(() => {
      console.log('Fine')
    })
    .catch(e => {
      state.errorUpdatePassPerfil = true
      if (e.response.data.message) {
        state.msgErrorUpdatePassPerfil = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgErrorUpdatePassPerfil = message
      }
    })
    .finally(() => {
      console.log('updatePassword Done')
    })
}
// ---||  [ obtener info newslatter ]  ||--- //
export async function getInfoNewsLatter({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    url: '/layouts/get_descuento_newsletter',
  }).then(response => {
    state.tituloNewslatter = response.data.data.titulo
    state.descripcionNewslatter = response.data.data.descripcion
  })
}
// ---||  [ NewsLatter ]  ||--- //
export async function suscribeDerek({ state }, payload) {
  if ($store.state.loaderDerek) return
  $store.state.loaderDerek = true
  state.errorNewsLatter = false
  state.msgerrorNewsLatter = ''
  var data = new FormData()
  data.append('email', payload.correo)
  data.append('nombres', payload.name)
  data.append('dia_cumpleanos', payload.day)
  data.append('mes_cumpleanos', payload.month)
  data.append('sms', payload.sms)
  data.append('telefono', payload.tel)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/mails/newsletter/register',
    data: data,
  })
    .catch(e => {
      state.errorNewsLatter = true
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgerrorNewsLatter = message
    })
    .finally(() => {
      console.log('SuscribeNewsLatter Done')
      $store.state.loaderDerek = false

      setTimeout(() => {
        state.msgerrorNewsLatter = ''
      }, 10000)
    })
}
// ---||  [ Agregar nueva dirección ]  ||--- //
export async function addNewDirection({ state }, payload) {
  state.errorDirection = false
  $store.state.loaderDerek = true
  var form = new FormData()
  // form.append("destinatario", payload.destinatario);
  form.append('direccion', payload.direccion)
  form.append('barrio', payload.barrio)
  form.append('departamento_id', payload.departamento_id)
  form.append('ciudad_id', payload.ciudad_id)
  form.append('observaciones', payload.observaciones)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/users/agregar_direccion',
    data: form,
  })
    .then(response => {
      state.directions = response.data.direcciones
      $store.state.cart.dataCart.direcciones = response.data.direcciones
    })
    .catch(e => {
      console.log(e)
      state.errorDirection = true
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('newDir Done')
    })
}
// ---||  [ Agregar nueva dirección ]  ||--- //
export async function deleteDirUser({ state }, payload) {
  state.errorDirection = false
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('direccion_id', payload)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/users/eliminar_direccion',
    data: form,
  })
    .then(response => {
      state.directions = response.data.direcciones
      $store.state.cart.dataCart.direcciones = response.data.direcciones
    })
    .catch(e => {
      console.log(e)
      state.errorDirection = true
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('deleteDir Done')
    })
}
// ---||  [ Actualizar dirección ]  ||--- //
export async function updateDirection({ state }, payload) {
  state.errorDirection = false
  $store.state.loaderDerek = true
  var form = new FormData()
  // form.append("destinatario", payload.destinatario);
  form.append('direccion', payload.direccion)
  form.append('barrio', payload.barrio)
  form.append('departamento_id', payload.departamento_id)
  form.append('ciudad_id', payload.ciudad_id)
  form.append('observaciones', payload.observaciones)
  form.append('direccion_id', payload.direccion_id)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/users/editar_direccion',
    data: form,
  })
    .then(response => {
      state.directions = response.data.direcciones
      $store.state.cart.dataCart
        ? ($store.state.cart.dataCart.direcciones = response.data.direcciones)
        : ''
    })
    .catch(e => {
      console.log(e)
      state.errorDirection = true
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('editDir Done')
    })
}
// ---||  [ Ordenes de usuario ]  ||--- //
export async function getOrders({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/users/orders',
  })
    .then(response => {
      state.orders.actives = response.data.activos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('Getorders Done')
    })
}
// ---||  [ Obtener info de pedido  ]  ||--- //
export async function getInfoOrder({ state }, payload) {
  state.dataOrder = null
  state.errorOrder = false
  state.msgErrorOrder = ''
  var form = new FormData()
  form.append('email', payload.email)
  form.append('pedido_id', payload.pedido_id)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/orders/order/track',
    data: form,
  })
    .then(response => {
      state.dataOrder = response.data.pedido
    })
    .catch(e => {
      console.log(e)
      let message = ''
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + ' '
      }
      state.msgErrorOrder = message
      state.errorOrder = true
    })
    .finally(() => {
      console.log('Get Order Done')
    })
}
// ---||  [ Obtener info de cupo  ]  ||--- //
export async function getInfoCupo({ state }, payload) {
  state.dataCupo = null
  state.errorCupo = false
  state.msgErrorCupo = ''
  var form = new FormData()
  form.append('documento', payload.doc)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/validateDocument',
    data: form,
  })
    .then(response => {
      state.dataCupo = response.data.data
      state.puede_abonar = response.data.puede_abonar
      state.facturas = response.data.facturas
      state.discount = 0
      if (response.data.facturas.length > 0) {
        for (let index in response.data.facturas) {
          state.discount += parseInt(
            response.data.facturas[index].vlr_descuento,
          )
        }
      }
    })
    .catch(e => {
      state.msgErrorCupo = e.response.data.message
      state.errorCupo = true
    })
    .finally(() => {
      console.log('getInfoCupo step #1 Done')
    })
}

// ---||  [ Obtener validate phone  ]  ||--- //
export async function checkEstadoCuenta({ state }, payload) {
  state.errorCupo = false
  state.msgErrorCupo = ''
  state.infoClient = null
  var form = new FormData()
  form.append('documento', payload.doc)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/generateToken',
    data: form,
  })
    .then(response => {
      state.infoClient = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupo = message
      state.errorCupo = true
    })
    .finally(() => {
      console.log('getInfoCupo checkEstadoCuenta')
    })
}

// ---||  [ Obtener validate phone  ]  ||--- //
export async function checkTelEstadoCuenta({ state }, payload) {
  state.errorCupo = false
  state.msgErrorCupo = ''
  var form = new FormData()
  form.append('celular', payload.celular)
  form.append('documento', payload.documento)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/validatePhone',
    data: form,
  })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupo = message
      state.errorCupo = true
    })
    .finally(() => {
      console.log('getInfoCupo checkTelEstadoCuenta')
    })
}

// ---||  [ Obtener info del cupo  ]  ||--- //
export async function getInfoCupoEstado({ state }, payload) {
  state.errorCupo = false
  state.msgErrorCupo = ''
  state.infoClient = null
  var form = new FormData()
  form.append('celular', payload.celular)
  form.append('documento', payload.documento)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/generateToken',
    data: form,
  })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupo = message
      state.errorCupo = true
    })
    .finally(() => {
      console.log('getInfoCupo getInfoCupoEstado')
    })
}

// ---||  [ Enviar código de tokenización cupo  ]  ||--- //
export async function sendCodeInfoCupo({ state }, payload) {
  var form = new FormData()
  form.append('documento', payload.doc)
  form.append('medio', payload.type)
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/generateToken',
    data: form,
  })
    .then(response => {
      state.dataSendCode = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorOrder = message
      state.msgErrorValidateCod = message
    })
    .finally(() => {
      console.log('sendCodeInfoCupo Done')
    })
}
export async function validateCodeInfoCupo2({ state }, payload) {
  var form = new FormData()
  form.append('documento', payload.doc)
  form.append('codigo', payload.codigo)
  state.msgErrorValidateCod = ''
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/validateToken2',
    data: form,
  })
    .then(response => {
      state.dataSendCode = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorOrder = message
      state.msgErrorValidateCod = message
    })
    .finally(() => {
      console.log('sendCodeInfoCupo Done')
    })
}
// ---||  [ Validar código de tokenización cupo  ]  ||--- //
export async function validateCodeInfoCupo({ state }, payload) {
  var form = new FormData()
  form.append('documento', payload.doc)
  form.append('codigo', payload.codigo)
  state.msgErrorValidateCod = ''
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
    },
    url: '/cupopersonal/accountStatus/validateToken',
    data: form,
  })
    .then(response => {
      state.dataSendCode = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorOrder = message
      state.msgErrorValidateCod = message
    })
    .finally(() => {
      console.log('sendCodeInfoCupo Done')
    })
}

/** Registrar una devolución */
export async function sendReturn({ state }, payload) {
  state.productos_error = null
  state.status_devolucion = false
  let form = new FormData()
  form.append('pedido_id', payload.pedido_id)
  form.append('email', payload.email)
  form.append('codigoAcceso', payload.codigoAcceso)
  payload.arrayproducts.forEach(e => {
    form.append('motivos_ids[]', e.motivo_id)
    form.append('pedido_detalles_ids[]', e.pedido_detalle_id)
  })
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/returns/generateReturnOrder',
    data: form,
  })
    .then(function(response) {
      state.status_devolucion = response.data.status == 1
    })
    .catch(function(error) {
      var message = ''
      if (error.response.data.productos_error) {
        message += `<p style="font-size: 1rem; margin-bottom: 0;">`
        error.response.data.productos_error.forEach(e => {
          message += `- El producto: <b>${e.producto_slug}</b>, no esta permitido para devolución. <br>`
        })
        message += `</p>`
        state.productos_error = message
      } else {
        var obj = error.response.data.errors
        for (const prop in obj) {
          message += `${obj[prop][0]} \n`
        }
        console.log(message)
      }
    })
}
