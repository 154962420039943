<template>
  <div class="container-newslatter">
    <div class="container-data-newslatter">
      <div v-if="!sended">
        <h3 style="color: #654062">
          {{ tituloNewslatter }}
        </h3>
        <p class="mb-4">
          {{ descripcionNewslatter }}
        </p>
      </div>
      <div v-if="!sended" class="container-form">
        <div class="w-100 position-relative">
          <input
            id="email"
            v-model="correo"
            type="email"
            style="min-height: 50px"
            class="payment-input w-100"
            :class="validateEmailNewslatter ? 'input-error' : ''"
            placeholder="Correo electrónico"
            @focus="focusInput = true"
          />
          <span
            v-if="correo != ''"
            class="newlatter-error-input"
            :style="validateEmailNewslatter ? 'color: red' : ''"
            >Correo electrónico</span
          >
        </div>
        <div
          :class="focusInput ? 'display-form__true' : ''"
          class="pt-4 display-form"
        >
          <div class="mb-4 w-100 position-relative">
            <input
              id="name"
              v-model="name"
              type="name"
              style="min-height: 50px"
              :class="validateNameNewslatter ? 'input-error' : ''"
              class="payment-input w-100"
              placeholder="Nombre completo"
            />
            <span
              v-if="name != ''"
              class="newlatter-error-input"
              :style="validateNameNewslatter ? 'color: red' : ''"
            >
              Nombre completo
            </span>
          </div>
          <div class="mb-4 w-100 position-relative">
            <input
              v-model="tel"
              class="payment-input w-100"
              placeholder="Celular"
              type="tel"
              :class="validateTelNewslatter ? 'input-error' : ''"
              name="celular"
            />
            <span
              v-if="tel != ''"
              class="newlatter-error-input"
              :style="validateTelNewslatter ? 'color: red' : ''"
            >
              Celular
            </span>
          </div>
          <p class="text-center" style="color :#949494">Fecha de cumpleaños</p>
          <div class="select-date-newslatter-footer mb-4">
            <div class="col-12 col-md-6 position-relative pe-0 pe-md-2">
              <select
                v-model="day"
                class="payment-input w-100"
                :class="!day && validateDateNewslatter ? 'input-error' : ''"
              >
                <option :value="null">Día</option>
                <option :value="'01'">1</option>
                <option :value="'02'">2</option>
                <option :value="'03'">3</option>
                <option :value="'04'">4</option>
                <option :value="'05'">5</option>
                <option :value="'06'">6</option>
                <option :value="'07'">7</option>
                <option :value="'08'">8</option>
                <option :value="'09'">9</option>
                <option :value="'10'">10</option>
                <option :value="'11'">11</option>
                <option :value="'12'">12</option>
                <option :value="'13'">13</option>
                <option :value="'14'">14</option>
                <option :value="'15'">15</option>
                <option :value="'16'">16</option>
                <option :value="'17'">17</option>
                <option :value="'18'">18</option>
                <option :value="'19'">19</option>
                <option :value="'20'">20</option>
                <option :value="'21'">21</option>
                <option :value="'22'">22</option>
                <option :value="'23'">23</option>
                <option :value="'24'">24</option>
                <option :value="'25'">25</option>
                <option :value="'26'">26</option>
                <option :value="'27'">27</option>
                <option :value="'28'">28</option>
                <option :value="'29'">29</option>
                <option :value="'30'">30</option>
                <option :value="'31'">31</option>
              </select>
              <span
                v-if="day"
                class="newlatter-error-input"
                :class="!day && validateDateNewslatter ? 'input-error' : ''"
              >
                Día
              </span>
            </div>
            <div
              class="col-12 col-md-6 position-relative mt-4 mt-md-0 ps-0 ps-md-2"
            >
              <select
                v-model="month"
                class="payment-input w-100"
                :class="!month && validateDateNewslatter ? 'input-error' : ''"
              >
                <option :value="null">Mes</option>
                <option :value="'01'">Enero</option>
                <option :value="'02'">Febrero</option>
                <option :value="'03'">Marzo</option>
                <option :value="'04'">Abril</option>
                <option :value="'05'">Mayo</option>
                <option :value="'06'">Junio</option>
                <option :value="'07'">Julio</option>
                <option :value="'08'">Agosto</option>
                <option :value="'09'">Septiembre</option>
                <option :value="'10'">Octubre</option>
                <option :value="'11'">Noviembre</option>
                <option :value="'12'">Diciembre</option>
              </select>
              <span
                v-if="month"
                class="newlatter-error-input"
                style="left: 1rem"
                :style="!month && validateDateNewslatter ? 'color: red' : ''"
              >
                Mes
              </span>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-start w-100 mb-2"
          >
            <label
              class="d-flex mx-0 ms-md-1"
              for="accept-tyc"
              style="color: transparent;"
            >
              a
              <input
                id="accept-tyc"
                v-model="tyc"
                class="tyc-checkbox"
                type="checkbox"
                checkmark="checkmark"
              />
              <span class="checkmark" style="z-index: 0"></span>
            </label>
            <p class="text-start" style="font-size: 12px">
              He leído y acepto la
              <router-link
                style="font-size: 12px; color: #654062;"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'politica-de-privacidad' },
                }"
              >
                Política de Privacidad y Tratamiento de datos personales.
              </router-link>
              Acepto recibir novedades y promociones. Aplican
              <router-link
                style="font-size: 12px; color: #654062;"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'descuento-suscripcion' },
                }"
              >
                Términos y Condiciones.
              </router-link>
            </p>
          </div>
          <button
            :disabled="!tyc"
            :style="tyc ? '' : 'opacity: 0.7; cursor: default'"
            class="mt-3 btn-primary w-100"
            @click="validateService()"
          >
            <b>Suscribirme</b>
          </button>
        </div>
        <div class="text-center w-100 my-3">
          <span class="msgError">{{ msgerrorNewsLatter }}</span>
        </div>
      </div>
      <div v-else>
        <img
          class="mb-3"
          style="z-index: 2"
          src="@/assets/icons/icon-check-newslatter.svg"
          alt="decorador-diamont"
        />
        <h2 class="mb-4" style="color: #654062">
          ¡Ahora haces parte de la exclusiva comunidad de derek!
        </h2>
        <p class="mb-5">
          Revisa tu correo electrónico en donde encontrarás tu cupón de
          descuento.
        </p>
        <button class="btn-primary px-5" @click="sended = false">
          <b>Entendido</b>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      correo: '',
      name: '',
      tel: '',
      day: null,
      month: null,
      focusInput: false,
      tyc: false,
      sended: false,
      validateEmailNewslatter: false,
      validateNameNewslatter: false,
      validateDateNewslatter: false,
      validateTelNewslatter: false,
    }
  },
  computed: {
    ...mapState('user', [
      'errorNewsLatter',
      'msgerrorNewsLatter',
      'tituloNewslatter',
      'descripcionNewslatter',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  methods: {
    ...mapActions('user', ['suscribeDerek', 'getInfoNewsLatter']),
    async validateService() {
      this.validateEmailNewslatter = false
      this.validateNameNewslatter = false
      this.validateDateNewslatter = false
      this.validateTelNewslatter = false
      if (
        this.validateEmail &&
        this.validatePhone &&
        this.name.length > 2 &&
        this.day && this.month
      ) {
        this.$store.state.user.errorNewsLatter = false
        await this.suscribeDerek({
          correo: this.correo,
          day: this.day,
          name: this.name,
          month: this.month,
          sms: 1,
          tel: this.tel,
        }).then(() => {
          if (!this.errorNewsLatter) {
            document.cookie = 'email=' + this.correo
            sendFBQEvent(this, 'Subscribe', {})
            this.correo = ''
            this.name = ''
            this.tel = ''
            this.day = null
            this.month = null
            this.sended = true
            this.focusInput = false
          }
          GA4SignUp('NewsLatter')
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateNameNewslatter = !(this.name.length > 2)
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
  },
  async mounted() {
    await this.getInfoNewsLatter()
  },
}
</script>
<style>
.container-newslatter {
  margin-bottom: 1rem;
  background-color: #efefef;
  padding: 2rem;
}
.container-newslatter .container-data-newslatter {
  max-width: 830px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: black;
}
.container-newslatter .container-data-newslatter .container-form {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}
.display-form {
  transition: all 0.5s linear 0s;
  overflow: hidden;
  height: 0;
}
.display-form__true {
  height: 430px;
}
@media (max-width: 768px) {
  .display-form__true {
    height: 510px;
  }
}
</style>
